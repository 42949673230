<acl-base-modal
    [title]="'features.dashboard.tickets.modals.upsert_anticipated_preparation_date.title' | translate"
    [niceLoadingOverlay]="loading$ | async"
>
    <form [formGroup]="formGroup">
        <mat-form-field appearance="outline" niceControlStatus class="py-5">
            <input
                #input
                matInput
                readonly
                formControlName="anticipatedPreparationDate"
                [ngxMatDatetimePicker]="picker"
                [ngxMatDatetimePickerFilter]="dateIsAfterOrEqualToTodayFilter"
                [placeholder]="'features.dashboard.tickets.modals.upsert_anticipated_preparation_date.anticipated_preparation_date' | translate"
                (click)="picker.open()"
            />
            <mat-datepicker-toggle [for]="picker" matSuffix></mat-datepicker-toggle>
            <ngx-mat-datetime-picker
                #picker
                color="accent"
                [hideTime]="false"
                [disableMinute]="false"
                [defaultTime]="[now.getHours(), now.getMinutes(), 0]"
            ></ngx-mat-datetime-picker>
        </mat-form-field>
    </form>

    <ng-container buttons>
        <div class="flex w-full">
            <button class="mat-accent w-full" mat-flat-button (click)="clickSubmit()">
                <span>{{ "general.save" | translate }}</span>
            </button>
        </div>
    </ng-container>
</acl-base-modal>
