import { DatePipe } from "@angular/common";
import { Pipe, PipeTransform } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

@Pipe({
    name: "localizedDate",
    pure: false,
    standalone: true
})
export class LocalizedDatePipe implements PipeTransform {
    constructor(private readonly translateService: TranslateService) {}

    public transform(value: any, pattern: string = "mediumDate"): any {
        if (pattern === "mediumDateWithoutYear") {
            pattern = this.getMediumDateWithoutYearPattern();
        }

        const datePipe: DatePipe = new DatePipe(this.translateService.defaultLang);

        if (value instanceof Date) {
            if (isNaN(value.getTime())) {
                return this.translateService.instant("general.not_available");
            }
        }

        if (value && value !== "0000-00-00") {
            return datePipe.transform(value, pattern);
        } else {
            return this.translateService.instant("general.not_available");
        }
    }

    private getMediumDateWithoutYearPattern(): string {
        if (this.translateService.defaultLang === "fr") {
            return "d MMMM";
        }
        return "MMMM d";
    }
}
