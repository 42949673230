import { NiceNavigationItem } from "@recursyve/nice-ui-kit.v2";

export const adminNavigation: NiceNavigationItem[] = [
    {
        id: "home",
        type: "group",
        children: [
            {
                id: "fertilizer_tickets",
                title: "navigation.fertilizer_tickets.title",
                type: "collapsable",
                iconType: "fontawesome",
                icon: "fa-duotone fa-bag-seedling text-primary",
                children: [
                    {
                        id: "bin",
                        title: "navigation.fertilizer_tickets.dumpsters",
                        type: "basic",
                        iconType: "fontawesome",
                        icon: "fa-duotone fa-dumpster",
                        link: "/dashboard/bin"
                    },
                    {
                        id: "dashboard",
                        title: "navigation.fertilizer_tickets.fertilizer_tickets",
                        type: "basic",
                        iconType: "fontawesome",
                        icon: "fa-duotone fa-bars-staggered",
                        link: "/dashboard/ticket"
                    }
                ]
            },
            { type: "divider" },
            {
                id: "shipping_order_tickets",
                title: "navigation.shipping_order_tickets.title",
                type: "collapsable",
                iconType: "fontawesome",
                icon: "fa-duotone fa-dolly text-primary",
                children: [
                    {
                        id: "dashboard",
                        title: "navigation.shipping_order_tickets.shipping_orders",
                        type: "basic",
                        iconType: "fontawesome",
                        icon: "fa-duotone fa-bars-staggered",
                        link: "/dashboard/shipping-order"
                    }
                ]
            },
            { type: "divider" },
            {
                id: "shipping",
                title: "navigation.shipping.title",
                type: "collapsable",
                iconType: "fontawesome",
                icon: "fa-duotone fa-truck-moving text-primary",
                children: [
                    {
                        id: "dashboard",
                        title: "navigation.shipping.tickets_to_deliver",
                        type: "basic",
                        iconType: "fontawesome",
                        icon: "fa-duotone fa-bars-staggered",
                        link: "/dashboard/to-deliver"
                    }
                ]
            },
            { type: "divider" },
            {
                id: "equipments",
                title: "navigation.equipments.title",
                type: "collapsable",
                iconType: "fontawesome",
                icon: "fa-duotone fa-gears text-primary",
                children: [
                    {
                        id: "dashboard",
                        title: "navigation.equipments.spreader",
                        type: "basic",
                        iconType: "fontawesome",
                        icon: "fa-duotone fa-shower-down",
                        link: "/dashboard/spreader"
                    },
                    {
                        id: "dashboard",
                        title: "navigation.equipments.grain_box",
                        type: "basic",
                        iconType: "fontawesome",
                        icon: "fa-duotone fa-toolbox",
                        link: "/dashboard/grain-box"
                    },
                    {
                        id: "dashboard",
                        title: "navigation.equipments.nurse",
                        type: "basic",
                        iconType: "fontawesome",
                        icon: "fa-duotone fa-seedling",
                        link: "/dashboard/nurse"
                    },
                    {
                        id: "dashboard",
                        title: "navigation.equipments.vapam",
                        type: "basic",
                        iconType: "fontawesome",
                        icon: "fa-duotone fa-tractor",
                        link: "/dashboard/vapam"
                    },
                    {
                        id: "dashboard",
                        title: "navigation.equipments.pump",
                        type: "basic",
                        iconType: "fontawesome",
                        icon: "fa-duotone fa-pump",
                        link: "/dashboard/pump"
                    },
                    {
                        id: "dashboard",
                        title: "navigation.equipments.other",
                        type: "basic",
                        iconType: "fontawesome",
                        icon: "fa-duotone fa-screwdriver-wrench",
                        link: "/dashboard/other"
                    }
                ]
            }
        ]
    }
];
