<div class="flex flex-col pb-2 px-4">
    <div class="flex justify-between items-center w-full">
        <span class="h3 text-primary">{{ title }}</span>

        <button mat-icon-button class="translate-x-2" (click)="clickCancel()">
            <mat-icon>
                <i class="fa-solid fa-regular fa-xmark icon-size-5 text-primary"></i>
            </mat-icon>
        </button>
    </div>

    <span *ngIf="subtitle" [innerHTML]="subtitle"></span>
</div>

<div class="w-full h-full overflow-y-auto px-4 max-h-dvh-60">
    <ng-content></ng-content>
</div>

<div class="flex justify-end w-full px-4">
    <ng-content select="[buttons]"></ng-content>
</div>
