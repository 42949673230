import { Directive } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { NiceModalOnClickDirective } from "@recursyve/nice-ui-kit.v2";
import { Tickets } from "../../../../../api/acl/ticket/models/tickets.model";
import { UpsertTicketAnticipatedPreparationDateModalComponent } from "./upsert-ticket-anticipated-preparation-date-modal.component";
import { UpsertTicketAnticipatedPreparationDateModalData } from "./upsert-ticket-anticipated-preparation-date-modal.data";

@Directive({
    selector: "[aclUpsertTicketAnticipatedPreparationDateModal]",
    standalone: true
})
export class UpsertTicketAnticipatedPreparationDateModalDirective extends NiceModalOnClickDirective<
    UpsertTicketAnticipatedPreparationDateModalComponent,
    Tickets | undefined,
    UpsertTicketAnticipatedPreparationDateModalData
> {
    constructor(public matDialog: MatDialog) {
        super(matDialog, UpsertTicketAnticipatedPreparationDateModalComponent, {
            autoFocus: false,
            restoreFocus: false,
            panelClass: "min-w-80"
        });
    }
}
