import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { setLoadingAndError } from "@datorama/akita";
import { EMPTY, from, identity, Observable, of } from "rxjs";
import { catchError, filter, map, switchMap, tap } from "rxjs/operators";
import { Accounts } from "../api/acl/account/models/accounts.model";
import { RolesEnum } from "../api/acl/account/models/enums/roles.enum";
import { ApiService } from "../api/api.service";
import { jsonToMap } from "../common/functions/json-to-map.function";
import { mapToJson } from "../common/functions/map-to-json.function";
import { NavigationService } from "../navigation/navigation.service";
import { AppQuery } from "./app.query";
import { AppStore } from "./app.store";

@Injectable({
    providedIn: "root"
})
export class AppService {
    constructor(
        private appStore: AppStore,
        private appQuery: AppQuery,
        private api: ApiService,
        private router: Router,
        private navigationService: NavigationService
    ) {}

    public init(): void {
        this.loadCurrentRole();
    }

    public loadServerState() {
        this.appStore.setLoading(true);
        this.api.info
            .getInfo()
            .pipe(
                tap(serverState => {
                    this.appStore.update({ serverState });
                    this.appStore.setLoading(false);
                }),
                catchError(e => {
                    this.appStore.setError(e);
                    this.appStore.setLoading(false);
                    return of(e);
                })
            )
            .subscribe();
        this.appStore.setLoading(false);
    }

    public loadCurrentUser(): Observable<void> {
        return this.api.account.getInfo().pipe(
            setLoadingAndError(this.appStore),
            map(account => {
                this.appStore.update({ currentUser: account });

                const currentRole = localStorage.getItem("role");
                const accountHasCurrentRole = account.roles.some(role => role.name === currentRole);
                if (currentRole && accountHasCurrentRole) {
                    this.setNavigation();
                } else {
                    this.loadLastSelectedRole(account);
                }
            }),
            catchError(() => EMPTY)
        );
    }

    public logout(): Observable<void> {
        return this.api.auth.logout().pipe(
            switchMap(() => from(this.router.navigate(["/login"]))),
            filter(identity),
            map(() => {
                this.setLastSelectedRole();
                this.appStore.update({ currentUser: null, currentRole: null });
                localStorage.removeItem("role");
            })
        );
    }

    public setCurrentRole(role: RolesEnum, redirect = true): void {
        localStorage.setItem("role", role);

        const previousRole = this.appQuery.getValue().currentRole;

        this.appStore.update({ currentRole: role });
        this.setNavigation();

        // Don't redirect if you switch between account with basically the same permissions.
        if (
            [RolesEnum.Admin, RolesEnum.Counter, RolesEnum.Manager].includes(previousRole) &&
            [RolesEnum.Admin, RolesEnum.Counter, RolesEnum.Manager].includes(role)
        ) {
            return;
        }

        if (redirect) {
            this.router.navigateByUrl("/dashboard");
        }
    }

    public loadCurrentRole(): void {
        const role = localStorage.getItem("role");
        this.appStore.update({
            currentRole: role as RolesEnum
        });
    }

    public setNavigation(): void {
        const { currentRole } = this.appQuery.getValue();
        this.navigationService.setNavigation(currentRole);
    }

    private setLastSelectedRole(): void {
        const { currentUser, currentRole } = this.appStore.getValue();
        if (!currentRole || !currentUser) {
            return;
        }

        const lastSelectedRolesJson = localStorage.getItem("lastSelectedRoles");
        const lastSelectedRolesMap = jsonToMap<number, RolesEnum>(lastSelectedRolesJson);
        lastSelectedRolesMap.set(currentUser.id, currentRole);

        localStorage.setItem("lastSelectedRoles", mapToJson(lastSelectedRolesMap));
    }

    private loadLastSelectedRole(account: Accounts): void {
        const lastSelectedRolesJson = localStorage.getItem("lastSelectedRoles");
        const lastSelectedRolesMap = jsonToMap<number, RolesEnum>(lastSelectedRolesJson);
        const lastSelectedRole = lastSelectedRolesMap.get(account.id);
        const accountHasLastSelectedRole = account.roles.some(role => role.name === lastSelectedRole);
        if (lastSelectedRole && accountHasLastSelectedRole) {
            this.setCurrentRole(lastSelectedRole);
        } else {
            this.setCurrentRole(account.roles[0]?.name);
        }
    }
}
