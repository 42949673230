<div
    class="p-4 w-full bg-white rounded-md"
    fxLayout="row"
    fxLayoutAlign="start center"
    [class.select-none]="!selectable"
    fxLayoutGap="16px"
>
    <div fxLayout="column" fxLayoutAlign="center start" fxFlex="0 1 120px" class="column">
        <span class="small-header">{{ "ticket.list.batch.batch_number" | translate }}</span>
        <span class="h3">#{{ ticket?.batchNumber }}</span>
    </div>

    <div fxLayout="column" fxLayoutAlign="center start" fxFlex="0 1 300px" fxLayoutGap="4px" class="column">
        <span class="truncate">{{ ticket?.client?.name }}</span>
        <div class="h5 mt-2 font-bold">{{ ticket?.formula?.description }}</div>
    </div>

    <div fxLayout="column" fxLayoutAlign="center start" fxFlex="0 1 250px" class="column">
        <ng-container *ngIf="ticket?.notes?.length">
            <span class="small-header">{{ "ticket.list.batch.notes" | translate }}</span>
            <span class="font-bold truncate">{{ ticket.notes }}</span>
        </ng-container>
    </div>

    <div fxLayout="column" fxLayoutAlign="center start" fxFlex="0 1 200px" class="column">
        <ng-container *ngIf="ticket?.ticketBins?.length">
            <span class="small-header">{{ "ticket.list.batch.bin" | translate }}</span>
            <span class="h3">{{ formattedBinNumber }}</span>
        </ng-container>
    </div>

    <div fxLayout="column" fxLayoutAlign="center start" fxFlex="0 1 200px" class="column">
        <ng-container *ngIf="ticket?.preparedBy">
            <span class="small-header">{{ "ticket.list.batch.prepared_by" | translate }}</span>
            <span class="h3 truncate">{{ ticket?.preparedBy?.fullName }}</span>
        </ng-container>
    </div>

    <div *ngIf="showQuantity" fxLayout="column" fxLayoutAlign="center start" fxFlex="0 1 150px" class="column">
        <span> <span class="font-bold">{{ ticket?.quantity }}</span> {{ "ticket.list.batch.ton" | translate }} </span>
    </div>

    <div
        *ngIf="showDensity || showPoundsPerAcre"
        fxLayout="column"
        fxLayoutAlign="center start"
        fxFlex="0 1 200px"
        class="column"
    >
        <span *ngIf="showDensity" class="h3">
            {{ ticket?.formula?.density | number: "1.0-2" }} <small>kg/pi<sup>3</sup></small>
        </span>
        <span *ngIf="showPoundsPerAcre" class="h3">
            {{ ticket?.formula?.poundsPerAcre | number: "1.0-2" }} <small>lbs/hectare</small>
        </span>
    </div>

    <div fxLayout="column" fxLayoutAlign="center end" fxFlex="0 1 125px" class="column">
        <div *ngIf="ticket?.equipment as equipment" class="flex flex-col">
            <span class="small-header">{{ "ticket.list.batch.equipments" | translate }}</span>
            <span>{{ equipment.identifier }}</span>
        </div>
    </div>

    <div fxLayout="column" fxLayoutAlign="center end" fxFlex="0 1 75px" class="column">
        <span> {{ ticket?.shippingDate | localizedDate: "shortTime" }} </span>
    </div>

    <ng-container *ifRole="[RolesEnum.Counter, RolesEnum.Admin, RolesEnum.Manager]">
        <div fxLayout="column" fxLayoutAlign="center end" class="w-full" *ngIf="actionMenu" fxFlex="0 1 75px">
            <div fxLayout="row" fxLayoutAlign="end center" class="w-full">
                <button
                    mat-icon-button
                    [matMenuTriggerFor]="actionMenu"
                    [matMenuTriggerData]="actionMenuData"
                    aria-label="Actions"
                    (click)="$event.stopPropagation()"
                >
                    <mat-icon>more_vert</mat-icon>
                </button>
            </div>
        </div>
    </ng-container>
</div>
