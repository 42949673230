import { isPropertyDefined, isPropertyDefinedCb } from "@recursyve/nice-ts-utils";
import { Equipments } from "../../api/acl/equipments/models/equipments.model";

export function sortEquipments(equipments: Equipments[]): Equipments[] {
    const equipmentsWithIdentifier = equipments.filter(isPropertyDefinedCb("identifier"));
    const equipmentsWithoutIdentifier = equipments.filter(equipment => !isPropertyDefined(equipment, "identifier"));

    return equipmentsWithIdentifier
        .sort((equipmentA, equipmentB) =>
            equipmentA.identifier.localeCompare(equipmentB.identifier, undefined, { numeric: true })
        )
        .concat(equipmentsWithoutIdentifier);
}
