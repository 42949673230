import { Directive, ElementRef } from "@angular/core";

@Directive({
    selector: "[aclCustomList]"
})
export class CustomListDirective {
    constructor(elementRef: ElementRef) {
        elementRef.nativeElement.classList.add("acl-custom-list");
    }
}
