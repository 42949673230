import { NgxMatDatetimePickerModule } from "@angular-material-components/datetime-picker";
import { AsyncPipe } from "@angular/common";
import { Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { TranslateModule } from "@ngx-translate/core";
import { GeneratedFormGroup, NgxFormGeneratorProvider } from "@recursyve/ngx-form-generator";
import { isDefined, isPropertyDefined } from "@recursyve/nice-ts-utils";
import { NiceBaseFormModule, NiceFormErrorModule, NiceLoadingSpinnerModule } from "@recursyve/nice-ui-kit.v2";
import { isAfter, isSameDay } from "date-fns";
import { Moment } from "moment";
import { filter, map, Subject, takeUntil } from "rxjs";
import { BaseModalComponent } from "../../../../components/base-modal/base-modal.component";
import { UpsertTicketAnticipatedPreparationDateModalQuery } from "./store/upsert-ticket-anticipated-preparation-date-modal.query";
import { UpsertTicketAnticipatedPreparationDateModalService } from "./store/upsert-ticket-anticipated-preparation-date-modal.service";
import { UpsertTicketAnticipatedPreparationDateModalStore } from "./store/upsert-ticket-anticipated-preparation-date-modal.store";
import { UpsertTicketAnticipatedPreparationDateModalData } from "./upsert-ticket-anticipated-preparation-date-modal.data";
import { UpsertTicketAnticipatedPreparationDateForm } from "./upsert-ticket-anticipated-preparation-date.form";

@Component({
    selector: "acl-upsert-ticket-anticipated-preparation-date-modal",
    templateUrl: "./upsert-ticket-anticipated-preparation-date-modal.template.html",
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [
        AsyncPipe,
        BaseModalComponent,
        MatButtonModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatInputModule,
        NgxMatDatetimePickerModule,
        NiceBaseFormModule,
        NiceFormErrorModule,
        NiceLoadingSpinnerModule,
        ReactiveFormsModule,
        TranslateModule
    ],
    providers: [
        NgxFormGeneratorProvider.forFeature([UpsertTicketAnticipatedPreparationDateForm]),
        UpsertTicketAnticipatedPreparationDateModalStore,
        UpsertTicketAnticipatedPreparationDateModalService,
        UpsertTicketAnticipatedPreparationDateModalQuery
    ]
})
export class UpsertTicketAnticipatedPreparationDateModalComponent implements OnInit, OnDestroy {
    protected readonly loading$ = this.query.selectLoading();
    protected readonly ticket$ = this.query.ticket$;

    protected readonly now = new Date();

    private readonly unsubscribeAll$ = new Subject<void>();

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: UpsertTicketAnticipatedPreparationDateModalData,
        private readonly matDialogRef: MatDialogRef<UpsertTicketAnticipatedPreparationDateModalComponent>,
        private readonly service: UpsertTicketAnticipatedPreparationDateModalService,
        private readonly store: UpsertTicketAnticipatedPreparationDateModalStore,
        private readonly query: UpsertTicketAnticipatedPreparationDateModalQuery,
        public formGroup: GeneratedFormGroup<UpsertTicketAnticipatedPreparationDateForm>
    ) {}

    public ngOnInit(): void {
        this.service
            .getTicketById(this.data.ticketId)
            .pipe(
                map(ticket => ticket?.anticipatedPreparationDate),
                filter(isDefined),
                takeUntil(this.unsubscribeAll$)
            )
            .subscribe(anticipatedPreparationDate =>
                this.formGroup.patchValue(
                    new UpsertTicketAnticipatedPreparationDateForm({ anticipatedPreparationDate })
                )
            );
    }

    public ngOnDestroy(): void {
        this.service.reset();
        this.unsubscribeAll$.next();
        this.unsubscribeAll$.complete();
    }

    public clickSubmit(): void {
        if (this.formGroup.invalid) {
            this.formGroup.markAllAsTouched();
            return;
        }

        const { ticket } = this.store.getValue();
        const dto = this.formGroup.getRawValue()?.toDto();
        if (!isPropertyDefined(ticket, "id") || !dto) {
            return;
        }

        this.service
            .upsertTicketAnticipatedPreparationDate(ticket.id, dto)
            .pipe(takeUntil(this.unsubscribeAll$))
            .subscribe(ticket => this.matDialogRef.close(ticket));
    }

    protected dateIsAfterOrEqualToTodayFilter(moment: Moment | null): boolean {
        if (!isDefined(moment)) {
            return false;
        }

        const date = moment.toDate();
        const now = new Date();

        return isAfter(date, now) || isSameDay(date, now);
    }
}
