import { Injectable } from "@angular/core";
import { Store, StoreConfig } from "@datorama/akita";
import { Tickets } from "../../../../../../api/acl/ticket/models/tickets.model";

export interface UpsertTicketAnticipatedPreparationDateModalState {
    ticket: Tickets | null;
}

const initialState: UpsertTicketAnticipatedPreparationDateModalState = {
    ticket: null
};

@StoreConfig({ name: "upsert-ticket-anticipated-preparation-date", resettable: true })
@Injectable()
export class UpsertTicketAnticipatedPreparationDateModalStore extends Store<UpsertTicketAnticipatedPreparationDateModalState> {
    constructor() {
        super(initialState);
    }
}
