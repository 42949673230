import { AbstractControl, ValidationErrors } from "@angular/forms";
import { ValidatorsHandler } from "@recursyve/ngx-form-generator";
import { isPropertyDefined } from "@recursyve/nice-ts-utils";
import { isAfter, isDate, isSameDay } from "date-fns";

export function DateIsAfterOrEqual(date: Date | number): PropertyDecorator {
    return ValidatorsHandler.setup((control: AbstractControl): ValidationErrors | null => {
        if (!isPropertyDefined(control, "value")) {
            return null;
        }

        const formattedValue = new Date(control.value);
        if (!isDate(formattedValue)) {
            return null;
        }

        if (isAfter(formattedValue, date) || isSameDay(formattedValue, date)) {
            return null;
        }

        return { date_is_after_or_equal: true };
    });
}
