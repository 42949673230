import { Store, StoreConfig } from "@datorama/akita";
import { Accounts } from "../api/acl/account/models/accounts.model";
import { RolesEnum } from "../api/acl/account/models/enums/roles.enum";
import { InfoModel } from "../api/info/models/info.model";

export interface AppState {
    serverState: InfoModel | null;
    currentUser: Accounts | null;
    currentRole: RolesEnum | null;
}

const initialState: AppState = {
    serverState: null,
    currentUser: null,
    currentRole: null
};

@StoreConfig({ name: "app" })
export class AppStore extends Store<AppState> {
    constructor() {
        super(initialState);
    }
}
