import { EntityState, EntityStore, StoreConfig } from "@datorama/akita";
import { Tickets } from "../../../../../../api/acl/ticket/models/tickets.model";
import { TicketType } from "../../../../../../api/common/enums/ticket-type.enum";

export interface TicketListToDeliverState extends EntityState<Tickets> {
    ticketType: TicketType | null;
}

const initialState: TicketListToDeliverState = {
    ticketType: null
};

@StoreConfig({
    name: "ticket-list-to-deliver"
})
export class TicketListToDeliverStore extends EntityStore<TicketListToDeliverState> {
    constructor() {
        super(initialState);
    }
}
