import { Control, Required } from "@recursyve/ngx-form-generator";
import { NiceBaseForm } from "@recursyve/nice-ui-kit.v2";
import { UpsertTicketAnticipatedPreparationDateDto } from "../../../../../api/acl/ticket/dto/upsert-ticket-anticipated-preparation-date.dto";
import { DateIsAfterOrEqual } from "../../../../../validators/date-is-after-or-equal.validator";

export class UpsertTicketAnticipatedPreparationDateForm extends NiceBaseForm<
    UpsertTicketAnticipatedPreparationDateForm,
    UpsertTicketAnticipatedPreparationDateDto
> {
    @Control()
    @Required()
    @DateIsAfterOrEqual(new Date())
    anticipatedPreparationDate: Date;
}
