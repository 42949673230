import { Component, Input, ViewEncapsulation } from "@angular/core";
import { MatMenuPanel } from "@angular/material/menu";
import { RolesEnum } from "../../../../../../../api/acl/account/models/enums/roles.enum";
import { TicketStatusesEnum } from "../../../../../../../api/acl/ticket/enums/ticket-statuses.enum";
import { Tickets } from "../../../../../../../api/acl/ticket/models/tickets.model";

@Component({
    selector: "acl-ticket-list-card[ticket]",
    templateUrl: "./ticket-list-card.template.html",
    styleUrls: ["ticket-list-card.style.scss"],
    encapsulation: ViewEncapsulation.None
})
export class TicketListCardComponent {
    @Input()
    public ticket: Tickets;

    @Input()
    public actionMenu: MatMenuPanel;

    @Input()
    public actionMenuData: { ticket: Tickets };

    protected readonly RolesEnum = RolesEnum;

    public get formattedBinNumber(): string {
        return this.ticket?.bins
            ?.map(b => b.number)
            .reduce((prev, curr) => {
                if (!prev?.length) {
                    return `#${curr}`;
                }
                return prev + `, #${curr}`;
            }, "");
    }

    public get showQuantity(): boolean {
        return this.ticket?.status == TicketStatusesEnum.Produced;
    }

    public get showDensity(): boolean {
        return this.ticket?.status == TicketStatusesEnum.Produced;
    }

    public get showPoundsPerAcre(): boolean {
        return this.ticket?.status == TicketStatusesEnum.Produced;
    }
}
