import { Component, HostListener, OnDestroy, ViewEncapsulation } from "@angular/core";
import { GeneratedFormGroup, ngxFormGeneratorFactory } from "@recursyve/ngx-form-generator";
import { niceAnimations } from "@recursyve/nice-ui-kit.v2";
import { Subject, takeUntil } from "rxjs";
import { BaseFormComponent } from "../../components/base-form/base-form.component";
import { KeyboardCodes } from "../../utils/keyboard.utils";
import { LoginForm } from "./login.form";
import { LoginQuery } from "./store/login.query";
import { LoginService } from "./store/login.service";

@Component({
    selector: "acl-login",
    templateUrl: "login.template.html",
    styleUrls: ["login.style.scss"],
    encapsulation: ViewEncapsulation.None,
    animations: niceAnimations,
    providers: [
        {
            provide: GeneratedFormGroup,
            useFactory: ngxFormGeneratorFactory(LoginForm)
        }
    ]
})
export class LoginComponent extends BaseFormComponent<LoginForm> implements OnDestroy {
    public loading$ = this.query.selectLoading();
    public error$ = this.query.selectError();

    private readonly unsubscribeAll$ = new Subject<void>();

    constructor(formGroup: GeneratedFormGroup<LoginForm>, private service: LoginService, private query: LoginQuery) {
        super(formGroup);
    }

    @HostListener("keyup", ["$event"])
    public onChange(event: KeyboardEvent) {
        if (event?.code === KeyboardCodes.Enter) {
            this.login();
        }
    }

    public ngOnDestroy(): void {
        this.unsubscribeAll$.next();
        this.unsubscribeAll$.complete();
    }

    protected login(): void {
        if (!this.validate()) {
            return;
        }

        const pin = this.formGroup.getRawValue().pin.toString();
        if (!pin || !pin.length) {
            return;
        }

        this.service.login(pin).pipe(takeUntil(this.unsubscribeAll$)).subscribe();
    }
}
