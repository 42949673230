<div class="overflow-x-hidden h-full pb-8">
    <div class="mb-0" fxLayout="row" fxLayoutAlign.gt-md="start" fxLayoutAlign.lt-lg="center">
        <h3 class="text-base font-bold leading-normal my-4">{{ title }}</h3>
    </div>
    <ng-container *ngIf="tickets?.length; else emptyStateRef">
        <ng-container *ngIf="dragDrop; else nonDraggableList">
            <ng-container *ngTemplateOutlet="draggableList"></ng-container>
        </ng-container>
    </ng-container>
</div>

<ng-template #draggableList>
    <mat-list
        class="rounded-xl"
        fxHide.lt-md
        aclCustomList
        cdkDropList
        [id]="dragDropId"
        [cdkDropListSortingDisabled]="dragDropSortingDisabled"
        [cdkDropListConnectedTo]="dragDropConnectedTo"
        (cdkDropListDropped)="onDragDropped($event)"
    >
        <ng-container *ngFor="let ticket of tickets; index as index">
            <ng-container *resolve="ticket | ticketHasBeenPrepared as currentTicketHasBeenPrepared">
                <ng-container *resolve="(tickets | at: index + 1) | ticketHasBeenPrepared as nextTicketHasBeenPrepared">
                    <ng-container *resolve="(tickets | at: index + 1)?.isArchived as nextTicketIsArchived">
                        <div
                            class="ticket border-accent bg-white"
                            cdkDrag
                            [cdkDragData]="ticket"
                            [class.ticket-warning]="currentTicketHasBeenPrepared && !ticket.isArchived"
                            [class.ticket-archived]="ticket.isArchived"
                            [ngClass]="{
                                'mb-0.5': currentTicketHasBeenPrepared && nextTicketHasBeenPrepared || ticket.isArchived&& nextTicketIsArchived
                            }"
                        >
                            <acl-ticket-list-tile
                                [ticket]="ticket"
                                [ticketType]="ticketType"
                                [actionMenu]="actionMenu"
                                [actionMenuData]="{ ticket: ticket }"
                                [selectable]="false"
                                [routerLink]="[ticket.id | ticketViewRouterLink: ticketType]"
                                (onClickToggleIsArchivedTicket)="onClickToggleIsArchivedTicket.emit($event)"
                                (onClickCompleteTicket)="onClickCompleteTicket.emit($event)"
                                (onClickMoveTicketToToDeliver)="onClickMoveTicketToToDeliver.emit($event)"
                                (onUpdateAnticipatedPreparationDate)="onUpdateAnticipatedPreparationDate.emit($event)"
                            >
                            </acl-ticket-list-tile>
                        </div>
                        <mat-divider
                            *ngIf="
                                index < tickets.length - 1 &&
                                !currentTicketHasBeenPrepared &&
                                !nextTicketHasBeenPrepared &&
                                !ticket.isArchived&&
                                !nextTicketIsArchived
                            "
                        ></mat-divider>
                    </ng-container>
                </ng-container>
            </ng-container>
        </ng-container>
    </mat-list>
    <div
        fxHide.gt-sm
        cdkDropList
        [id]="dragDropId + 'Mobile'"
        [cdkDropListSortingDisabled]="dragDropSortingDisabled"
        [cdkDropListConnectedTo]="dragDropConnectedTo + 'Mobile'"
        (cdkDropListDropped)="onDragDropped($event)"
    >
        <div *ngFor="let ticket of tickets" cdkDrag [cdkDragData]="ticket" class="px-4">
            <acl-ticket-list-card
                [ticket]="ticket"
                [actionMenu]="actionMenu"
                [actionMenuData]="{ ticket: ticket }"
                [routerLink]="[ticket.id | ticketViewRouterLink: ticketType]"
            >
            </acl-ticket-list-card>
        </div>
    </div>
</ng-template>

<ng-template #nonDraggableList>
    <mat-list fxHide.lt-md aclCustomList>
        <ng-container *ngFor="let ticket of tickets; index as index">
            <ng-container *resolve="ticket | ticketHasBeenPrepared as currentTicketHasBeenPrepared">
                <ng-container *resolve="(tickets | at: index + 1) | ticketHasBeenPrepared as nextTicketHasBeenPrepared">
                    <ng-container *resolve="(tickets | at: index + 1)?.isArchived as nextTicketIsArchived">
                        <div
                            class="ticket border-accent bg-white"
                            [class.ticket-warning]="currentTicketHasBeenPrepared && !ticket.isArchived"
                            [class.ticket-archived]="ticket.isArchived"
                            [ngClass]="{
                            'mb-0.5': currentTicketHasBeenPrepared && nextTicketHasBeenPrepared || ticket.isArchived && nextTicketIsArchived
                        }"
                        >
                            <acl-ticket-list-tile
                                [ticket]="ticket"
                                [ticketType]="ticketType"
                                [actionMenu]="actionMenu"
                                [actionMenuData]="{ ticket: ticket }"
                                [selectable]="false"
                                [routerLink]="[ticket.id | ticketViewRouterLink: ticketType]"
                                (onClickToggleIsArchivedTicket)="onClickToggleIsArchivedTicket.emit($event)"
                                (onClickCompleteTicket)="onClickCompleteTicket.emit($event)"
                                (onClickMoveTicketToToDeliver)="onClickMoveTicketToToDeliver.emit($event)"
                                (onUpdateAnticipatedPreparationDate)="onUpdateAnticipatedPreparationDate.emit($event)"
                            >
                            </acl-ticket-list-tile>
                            <mat-divider
                                *ngIf="
                                    index < tickets.length - 1 &&
                                    !currentTicketHasBeenPrepared &&
                                    !nextTicketHasBeenPrepared &&
                                    !ticket.isArchived &&
                                    !nextTicketIsArchived
                                "
                            ></mat-divider>
                        </div>
                    </ng-container>
                </ng-container>
            </ng-container>
        </ng-container>
    </mat-list>
    <div fxHide.gt-sm>
        <acl-ticket-list-card
            *ngFor="let ticket of tickets"
            class="px-4"
            [ticket]="ticket"
            [actionMenu]="actionMenu"
            [actionMenuData]="{ ticket: ticket }"
            [routerLink]="[ticket.id | ticketViewRouterLink: ticketType]"
        >
        </acl-ticket-list-card>
    </div>
</ng-template>
