import { CommonModule } from "@angular/common";
import { Component, Input, ViewEncapsulation } from "@angular/core";
import { FlexModule } from "@angular/flex-layout";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatListModule } from "@angular/material/list";
import { MatMenuModule, MatMenuPanel } from "@angular/material/menu";
import { MatTooltipModule } from "@angular/material/tooltip";
import { TranslateModule } from "@ngx-translate/core";
import { RolesEnum } from "../../../../../../../../../api/acl/account/models/enums/roles.enum";
import { Tickets } from "../../../../../../../../../api/acl/ticket/models/tickets.model";
import { DirectivesModule } from "../../../../../../../../../directives/directives.module";
import { LocalizedDatePipe } from "../../../../../../../../../pipes/localized-date.pipe";

@Component({
    selector: "acl-batch-ticket-tile[ticket]",
    templateUrl: "./batch-ticket-tile.template.html",
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [
        FlexModule,
        TranslateModule,
        LocalizedDatePipe,
        MatButtonModule,
        MatMenuModule,
        MatIconModule,
        CommonModule,
        MatListModule,
        DirectivesModule,
        MatTooltipModule
    ]
})
export class BatchTicketTileComponent {
    @Input()
    public ticket: Tickets;

    @Input()
    public actionMenu: MatMenuPanel;

    @Input()
    public actionMenuData: { ticket: Tickets };

    @Input()
    public selectable = false;

    @Input()
    public formattedBinNumber: string;

    @Input()
    public showQuantity: boolean;

    @Input()
    public showDensity: boolean;

    @Input()
    public showPoundsPerAcre: boolean;

    protected readonly RolesEnum = RolesEnum;
}
