import { AsyncPipe, CommonModule } from "@angular/common";
import { Component, inject, ViewEncapsulation } from "@angular/core";
import { FlexLayoutModule } from "@angular/flex-layout";
import { FormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatMenuModule } from "@angular/material/menu";
import { MatToolbarModule } from "@angular/material/toolbar";
import { RouterModule } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { NiceNavigationService, ResolveDirective } from "@recursyve/nice-ui-kit.v2";
import { AvatarModule } from "ngx-avatars";
import { Roles } from "../../../../api/acl/account/models/roles.model";
import { IsDefinedPipe } from "../../../../pipes/is-defined.pipe";
import { AppQuery } from "../../../../store/app.query";
import { AppService } from "../../../../store/app.service";

@Component({
    selector: "acl-toolbar",
    templateUrl: "toolbar.template.html",
    styleUrls: ["./toolbar.style.scss"],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [
        AvatarModule,
        AsyncPipe,
        CommonModule,
        FlexLayoutModule,
        FormsModule,
        MatButtonModule,
        MatIconModule,
        MatMenuModule,
        MatToolbarModule,
        ResolveDirective,
        RouterModule,
        TranslateModule,
        IsDefinedPipe
    ]
})
export class ToolbarComponent {
    private readonly navigationService = inject(NiceNavigationService);
    private readonly appService = inject(AppService);
    private readonly appQuery = inject(AppQuery);

    protected readonly currentUser$ = this.appQuery.selectCurrentUser();
    protected readonly currentRole$ = this.appQuery.selectCurrentRole();

    protected toggleSidebarOpen(key: string) {
        this.navigationService.getComponent(key).toggle();
    }

    protected activateRole(role: Roles): void {
        this.appService.setCurrentRole(role.name);
    }

    protected logout(): void {
        /*
            We can't pipe takeUntil here since if we do, the execution won't make it to the map() of the
            AppService's logout() function
        */
        this.appService.logout().subscribe();
    }
}
