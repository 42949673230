import { Pipe, PipeTransform } from "@angular/core";
import { isDefined } from "@recursyve/nice-ts-utils";

@Pipe({
    name: "isDefined",
    standalone: true
})
export class IsDefinedPipe implements PipeTransform {
    public transform(value?: any): boolean {
        return isDefined(value);
    }
}
