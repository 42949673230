<mat-toolbar *ngIf="currentUser$ | async as currentUser" class="p-0 w-full">
    <div class="flex gt-ipad:justify-end justify-between w-full">
        <button class="block min-w-14 h-14 gt-ipad:hidden" mat-icon-button (click)="toggleSidebarOpen('navbar')">
            <mat-icon class="secondary-text">menu</mat-icon>
        </button>

        <div *resolve="currentRole$ | async as currentRole">
            <button
                class="min-w-16 h-16 user-button border-transparent normal-case avatar-button avatar-button px-11"
                mat-button
                disableRipple
                [matMenuTriggerFor]="userMenu"
            >
                <ngx-avatars size="42" [name]="currentUser.fullName"></ngx-avatars>
                <div class="hidden md:flex flex-col items-start">
                    <span class="text-primary text-sm"> {{ currentUser.fullName }} </span>

                    <span *ngIf="currentRole | isDefined" class="text-sm text-primary/50">
                        {{ ("models.roles." + currentRole) | translate }}
                    </span>
                </div>
                <mat-icon class="text-xl text-primary">keyboard_arrow_down</mat-icon>
            </button>

            <mat-menu #userMenu="matMenu" [overlapTrigger]="false" class="switch-nested-menu-arrows">
                <button *ngIf="currentUser?.roles?.length > 1" mat-menu-item [matMenuTriggerFor]="subMenu">
                    <mat-icon>admin_panel_settings</mat-icon>
                    <span>{{ "features.dashboard.components.toolbar.change_account" | translate }}</span>
                </button>
                <button class="text-primary" mat-menu-item (click)="logout()">
                    <mat-icon>exit_to_app</mat-icon>
                    <span>Logout</span>
                </button>
            </mat-menu>

            <mat-menu #subMenu="matMenu">
                <ng-container *ngIf="currentRole">
                    <ng-container *ngFor="let role of currentUser.roles">
                        <button mat-menu-item (click)="activateRole(role)" *ngIf="currentRole !== role.name">
                            <mat-icon>account_circle</mat-icon>
                            <span>{{ ("models.roles." + role.name) | translate }}</span>
                        </button>
                    </ng-container>
                </ng-container>
            </mat-menu>
        </div>
    </div>
</mat-toolbar>
