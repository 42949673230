import { Injectable } from "@angular/core";
import { setLoadingAndError } from "@datorama/akita";
import { NiceToastService, showSuccessOrErrorToast } from "@recursyve/nice-ui-kit.v2";
import { catchError, EMPTY, Observable, tap } from "rxjs";
import { UpsertTicketAnticipatedPreparationDateDto } from "../../../../../../api/acl/ticket/dto/upsert-ticket-anticipated-preparation-date.dto";
import { Tickets } from "../../../../../../api/acl/ticket/models/tickets.model";
import { ApiService } from "../../../../../../api/api.service";
import { UpsertTicketAnticipatedPreparationDateModalStore } from "./upsert-ticket-anticipated-preparation-date-modal.store";

@Injectable()
export class UpsertTicketAnticipatedPreparationDateModalService {
    constructor(
        private readonly store: UpsertTicketAnticipatedPreparationDateModalStore,
        private readonly api: ApiService,
        private readonly niceToastService: NiceToastService
    ) {}

    public reset(): void {
        this.store.reset();
    }

    public getTicketById(id: number): Observable<Tickets> {
        return this.api.ticket.getById(id).pipe(
            setLoadingAndError(this.store),
            tap(ticket => this.store.update({ ticket })),
            catchError(() => EMPTY)
        );
    }

    public upsertTicketAnticipatedPreparationDate(
        id: number,
        dto: UpsertTicketAnticipatedPreparationDateDto
    ): Observable<Tickets> {
        return this.api.ticket.updateTicketAnticipatedPreparationDate(id, dto).pipe(
            setLoadingAndError(this.store),
            showSuccessOrErrorToast(this.niceToastService, {
                messageKey: "features.dashboard.tickets.modals.upsert_anticipated_preparation_date.toasts"
            }),
            catchError(() => EMPTY)
        );
    }
}
