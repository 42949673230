import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FlexModule } from "@angular/flex-layout";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatIconModule } from "@angular/material/icon";
import { MatMenuModule } from "@angular/material/menu";
import { TranslateModule } from "@ngx-translate/core";
import { DirectivesModule } from "../../../../../../../directives/directives.module";
import { LocalizedDatePipe } from "../../../../../../../pipes/localized-date.pipe";
import { TicketListCardComponent } from "./ticket-list-card.component";

@NgModule({
    imports: [
        MatCardModule,
        FlexModule,
        TranslateModule,
        LocalizedDatePipe,
        MatButtonModule,
        MatMenuModule,
        MatIconModule,
        CommonModule,
        DirectivesModule
    ],
    declarations: [TicketListCardComponent],
    exports: [TicketListCardComponent]
})
export class TicketListCardModule {}
