import { Tickets } from "../api/acl/ticket/models/tickets.model";
import { LexoRank } from "lexorank";

export class LexoRankUtils {
    public static getNewTicketRank(ticket: Tickets, beforeTicket: Tickets, afterTicket: Tickets, tickets: Tickets[]): LexoRank {
        if (beforeTicket && afterTicket) {
            return LexoRank.parse(afterTicket.rank).between(LexoRank.parse(beforeTicket.rank));
        }

        if (beforeTicket) {
            return LexoRank.parse(beforeTicket.rank).genPrev();
        }
        if (afterTicket) {
            return LexoRank.parse(afterTicket.rank).genNext();
        }

        return LexoRank.middle();
    }

    public static compareTickets(a: Tickets, b: Tickets): number {
        if (!!a.rank?.length != !!b.rank?.length) {
            return !!a ? 1 : -1;
        }
        if (!a.rank?.length && !b.rank?.length) {
            return 0;
        }
        return LexoRank.parse(a.rank).compareTo(LexoRank.parse(b.rank));
    }
}
