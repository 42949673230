<mat-card class="mb-2">
    <mat-card-content class="m-0">
        <div fxLayout="column" fxLayoutAlign="space-between start" fxLayoutGap="16px">
            <div class="w-full" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
                <div fxFlex="10">
                    <i class="fad fa-hashtag text-accent"></i>
                </div>
                <div fxFlex="1 0 auto">
                    <div fxLayout="row" fxLayoutAlign="space-between center">
                        <span class="font-bold"> {{ ticket?.batchNumber }} </span>
                        <ng-container *ifRole="[RolesEnum.Counter, RolesEnum.Admin, RolesEnum.Manager]">
                            <button
                                (click)="$event.stopPropagation()"
                                [matMenuTriggerFor]="actionMenu"
                                [matMenuTriggerData]="actionMenuData"
                                aria-label="Actions"
                                mat-icon-button
                                *ngIf="actionMenu"
                            >
                                <mat-icon>more_vert</mat-icon>
                            </button>
                        </ng-container>
                    </div>
                </div>
            </div>
            <div class="w-full" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
                <div fxFlex="10">
                    <i class="fad fa-atom text-accent"></i>
                </div>
                <div fxFlex="1 0 auto" class="max-w-80p">
                    <span class="font-bold truncate"> {{ ticket?.formula?.description }} </span>
                </div>
            </div>
            <div class="w-full" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
                <div fxFlex="10">
                    <i class="fad fa-farm text-accent"></i>
                </div>
                <div fxFlex="1 0 auto" class="max-w-80p">
                    <span class="font-bold truncate"> {{ ticket?.client?.name }} </span>
                </div>
            </div>
            <div *ngIf="ticket?.notes" class="w-full" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
                <div fxFlex="10">
                    <i class="fad fa-sticky-note text-accent"></i>
                </div>
                <div fxFlex="1 0 auto" class="max-w-80p">
                    <span class="font-bold truncate"> {{ ticket.notes }} </span>
                </div>
            </div>
            <div
                *ngIf="ticket?.bins?.length"
                class="w-full"
                fxLayout="row"
                fxLayoutAlign="start center"
                fxLayoutGap="16px"
            >
                <div fxFlex="10">
                    <i class="fad fa-dumpster text-accent"></i>
                </div>
                <div fxFlex="1 0 auto">
                    <span class="font-bold"> {{ formattedBinNumber }} </span>
                </div>
            </div>
            <div
                *ngIf="ticket?.preparedBy"
                class="w-full"
                fxLayout="row"
                fxLayoutAlign="start center"
                fxLayoutGap="16px"
            >
                <div fxFlex="10">
                    <i class="fad fa-user-hard-hat text-accent"></i>
                </div>
                <div fxFlex="1 0 auto">
                    <span class="font-bold"> {{ ticket.preparedBy.fullName }} </span>
                </div>
            </div>
            <div *ngIf="showQuantity" class="w-full" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
                <div fxFlex="10">
                    <i class="fad fa-weight-hanging text-accent"></i>
                </div>
                <div fxFlex="1 0 auto" class="max-w-80p">
                    <span class="truncate">
                        <span class="font-bold">{{ ticket?.quantity }}</span> {{ "ticket.list.batch.ton" | translate }}
                    </span>
                </div>
            </div>
            <div
                *ngIf="showDensity || showPoundsPerAcre"
                class="w-full"
                fxLayout="row"
                fxLayoutAlign="start center"
                fxLayoutGap="16px"
            >
                <div fxFlex="10">
                    <i class="fad fa-vial text-accent"></i>
                </div>
                <div fxFlex="1 0 auto" class="max-w-80p">
                    <span *ngIf="showDensity" class="truncate">
                        <span class="font-bold pr-4">{{ ticket?.formula?.density | number: "1.0-2" }}</span>
                        <small>kg/pi<sup>3</sup></small>
                    </span>
                    <span *ngIf="showPoundsPerAcre" class="truncate">
                        <span class="font-bold pr-4">{{ ticket?.formula?.poundsPerAcre | number: "1.0-2" }}</span>
                        <small>lbs/hectare</small>
                    </span>
                </div>
            </div>
            <div
                *ngIf="ticket?.shippingDate"
                class="w-full"
                fxLayout="row"
                fxLayoutAlign="start center"
                fxLayoutGap="16px"
            >
                <div fxFlex="10">
                    <i class="fad fa-clock text-accent"></i>
                </div>
                <div fxFlex="1 0 auto">
                    <span> {{ ticket.shippingDate | localizedDate: "shortTime" }} </span>
                </div>
            </div>
        </div>
    </mat-card-content>
</mat-card>
