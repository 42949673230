import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "at",
    standalone: true
})
export class AtPipe implements PipeTransform {
    public transform<T>(items: T[] | null | undefined, index: number): T | null {
        if (!items) {
            return null;
        }

        return items.at(index) ?? null;
    }
}
