import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { setLoadingAndError } from "@datorama/akita";
import { catchError, EMPTY, filter, identity, Observable, tap } from "rxjs";
import { ApiService } from "../../../api/api.service";
import { LoginStore } from "./login.store";

@Injectable()
export class LoginService {
    constructor(private store: LoginStore, private api: ApiService, private router: Router) {}

    public login(pin: string): Observable<boolean> {
        this.resetError();
        return this.api.auth.login({ pin }).pipe(
            setLoadingAndError(this.store),
            filter(identity),
            tap(() => this.router.navigate(["/dashboard"])),
            catchError(() => EMPTY)
        );
    }

    private resetError() {
        this.store.setError(null);
    }
}
