import { Pipe, PipeTransform } from "@angular/core";
import { isNullOrUndefined } from "@recursyve/nice-ui-kit.v2";

@Pipe({ name: "valueOrDash", standalone: true })
export class ValueOrDashPipe implements PipeTransform {
    public transform(value: unknown): string {
        if (isNullOrUndefined(value)) {
            return "-";
        }

        return `${value}`;
    }
}
