import { Pipe, PipeTransform } from "@angular/core";
import { TicketStatusesEnum } from "../api/acl/ticket/enums/ticket-statuses.enum";
import { Tickets } from "../api/acl/ticket/models/tickets.model";

@Pipe({
    name: "ticketHasBeenPrepared",
    standalone: true
})
export class TicketHasBeenPreparedPipe implements PipeTransform {
    public transform(ticket?: Tickets | null): boolean {
        if (!ticket) {
            return false;
        }

        return ticket.preparedAt && [TicketStatusesEnum.Waiting, TicketStatusesEnum.ToPrepare].includes(ticket.status);
    }
}
