import { NgClass, NgSwitch, NgSwitchCase } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatListModule } from "@angular/material/list";
import { ResolveDirective } from "@recursyve/nice-ui-kit.v2";
import { TicketHasBeenPreparedPipe } from "../../../../../../../pipes/ticket-has-been-prepared.pipe";
import { BatchTicketTileComponent } from "./components/batch-ticket-tile/batch-ticket-tile.component";
import { ShippingOrderTicketTileComponent } from "./components/shipping-order-ticket-tile/shipping-order-ticket-tile.component";
import { TicketListTileComponent } from "./ticket-list-tile.component";

@NgModule({
    imports: [
        NgSwitch,
        NgSwitchCase,
        BatchTicketTileComponent,
        ShippingOrderTicketTileComponent,
        ResolveDirective,
        MatListModule,
        NgClass,
        TicketHasBeenPreparedPipe
    ],
    declarations: [TicketListTileComponent],
    exports: [TicketListTileComponent]
})
export class TicketListTileModule {}
