import { DatabaseEntities } from "../../../common/models/database-entities.model";
import { Clients } from "../../client/models/clients.model";
import { Coords } from "../../coords/models/coords.model";
import { EquipmentStatusHistories } from "../../equipments-status-histories/models/equipment-status-histories.model";
import { ShippingOrderItems } from "../../shipping-orders/models/shipping-order-items.model";
import { Tickets } from "../../ticket/models/tickets.model";
import { EquipmentStatuses } from "./enums/equipment-statuses.enum";
import { EquipmentTypes } from "./enums/equipment-types.enum";
import { GrainBoxCapacities } from "./grain-box-capacities.model";
import { GrainBoxRollingTypes } from "./grain-box-rolling-types.model";
import { GrainBoxSizes } from "./grain-box-sizes.model";
import { GrainBoxWheels } from "./grain-box-wheels.model";
import { NurseWheels } from "./nurse-wheels.model";
import { OtherEquipmentNames } from "./other-equipment-names.model";
import { SpreaderHeights } from "./spreader-heights.model";
import { SpreaderWheels } from "./spreader-wheels.model";
import { SpreaderWidths } from "./spreader-widths.model";

export class Equipments extends DatabaseEntities<Equipments> {
    coordId: number;
    clientId: string;
    ticketId: number;

    identifier: string;
    brand: string;
    model: string;
    status: EquipmentStatuses;
    type: EquipmentTypes;
    statusChangedAt: Date;
    coord?: Coords;
    releaseYear: number;
    registrationPlate: string;
    serialNumber: string;
    note: string;
    localisation: string;
    wentWithClientAt: Date;
    tires: string;
    containerSize: number;

    spreaderStretchable: boolean;

    grainBoxParticularity: string;

    nurseSize: number;

    vapamLegs: number;

    changesHistory: EquipmentStatusHistories[];
    client?: Clients;
    ticket: Tickets;
    spreaderWidth: SpreaderWidths;
    spreaderHeight: SpreaderHeights;
    spreaderWheels: SpreaderWheels;
    grainBoxSize: GrainBoxSizes;
    grainBoxRollingType: GrainBoxRollingTypes;
    grainBoxWheels: GrainBoxWheels;
    grainBoxCapacity: GrainBoxCapacities;
    nurseWheels: NurseWheels;
    otherEquipmentName: OtherEquipmentNames;
    shippingOrderItems?: ShippingOrderItems[];
    archived: boolean;
}
