import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Accounts } from "../../account/models/accounts.model";
import { AclApi } from "../../acl.api";
import { LoginDto } from "../dto/login.dto";

@Injectable()
export class AuthApi extends AclApi {
    constructor(http: HttpClient) {
        super("auth", http);
    }

    public login(dto: LoginDto): Observable<boolean> {
        return this.post<Accounts>("login", dto).pipe(map(a => !!a));
    }

    public isLoggedIn(): Observable<boolean> {
        return this.get<{ loggedIn: boolean }>().pipe(map(data => data.loggedIn));
    }

    public logout(): Observable<void> {
        return this.delete();
    }
}
