export enum RolesEnum {
    Counter = "counter",
    Batch = "batch",
    Loader = "loader",
    Shipping = "shipping",
    Preparator = "preparator",
    Approver = "approver",
    Manager = "manager",
    Admin = "admin"
}
