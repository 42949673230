import { Injectable } from "@angular/core";
import { QueryEntity } from "@datorama/akita";
import { TicketStatusesEnum } from "../../../../../../api/acl/ticket/enums/ticket-statuses.enum";
import { LexoRankUtils } from "../../../../../../utils/lexo-rank.utils";
import { TicketListToDeliverState, TicketListToDeliverStore } from "./ticket-list-to-deliver.store";

@Injectable()
export class TicketListToDeliverQuery extends QueryEntity<TicketListToDeliverState> {
    public readonly tickets$ = this.selectAll({
        filterBy: ticket => ticket.status === TicketStatusesEnum.ToDeliver,
        sortBy: LexoRankUtils.compareTickets
    });

    constructor(store: TicketListToDeliverStore) {
        super(store);
    }
}
