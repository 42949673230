import { TailwindColors } from "../../../common/enums/tailwind-colors.enum";

export enum TicketStatusesEnum {
    Waiting = "waiting",
    ToProduce = "to_produce",
    InProduction = "in_production",
    Produced = "produced",
    ToPrepare = "to_prepare",
    InPreparation = "in_preparation",
    WaitingForApproval = "waiting_for_approval",
    Approved = "approved",
    ToDeliver = "to_deliver",
    Completed = "completed"
}

export const TICKETS_STATUS_COLOR: Record<TicketStatusesEnum, TailwindColors> = {
    [TicketStatusesEnum.Waiting]: TailwindColors.DarkCyan,
    [TicketStatusesEnum.ToProduce]: TailwindColors.Yellow,
    [TicketStatusesEnum.InProduction]: TailwindColors.Orange,
    [TicketStatusesEnum.Produced]: TailwindColors.Ruby,
    [TicketStatusesEnum.ToPrepare]: TailwindColors.Yellow,
    [TicketStatusesEnum.InPreparation]: TailwindColors.Orange,
    [TicketStatusesEnum.WaitingForApproval]: TailwindColors.DarkRed,
    [TicketStatusesEnum.Approved]: TailwindColors.Ruby,
    [TicketStatusesEnum.ToDeliver]: TailwindColors.Purple,
    [TicketStatusesEnum.Completed]: TailwindColors.Green
};
