import { DragDropModule } from "@angular/cdk/drag-drop";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ExtendedModule, FlexModule } from "@angular/flex-layout";
import { MatListModule } from "@angular/material/list";
import { RouterModule } from "@angular/router";
import { ResolveDirective } from "@recursyve/nice-ui-kit.v2";
import { CustomListModule } from "../../../../../../../directives/custom-list/custom-list.module";
import { DirectivesModule } from "../../../../../../../directives/directives.module";
import { AtPipe } from "../../../../../../../pipes/at.pipe";
import { TicketHasBeenPreparedPipe } from "../../../../../../../pipes/ticket-has-been-prepared.pipe";
import { ticketViewRouterLinkPipe } from "../../../../../../../pipes/ticket-view-router-link.pipe";
import { TicketListCardModule } from "../ticket-list-card/ticket-list-card.module";
import { TicketListTileModule } from "../ticket-list-tile/ticket-list-tile.module";
import { TicketListItemListComponent } from "./ticket-list-item-list.component";

@NgModule({
    imports: [
        CommonModule,
        ExtendedModule,
        CustomListModule,
        DragDropModule,
        MatListModule,
        TicketListTileModule,
        RouterModule,
        TicketListCardModule,
        FlexModule,
        DirectivesModule,
        TicketHasBeenPreparedPipe,
        AtPipe,
        ResolveDirective,
        ticketViewRouterLinkPipe
    ],
    declarations: [TicketListItemListComponent],
    exports: [TicketListItemListComponent]
})
export class TicketListItemListModule {}
