import { Routes } from "@angular/router";
import { RolesEnum } from "../../api/acl/account/models/enums/roles.enum";
import { TicketType } from "../../api/common/enums/ticket-type.enum";
import { AccessGuard } from "../../guards/access.guard";
import { TicketListToDeliverComponent } from "./tickets/lists/ticket-list-to-deliver/ticket-list-to-deliver.component";

export const DASHBOARD_ROUTES: Routes = [
    { path: "", redirectTo: "ticket", pathMatch: "full" },
    {
        path: "bin",
        loadChildren: () => import("./bins/bins.module").then(m => m.BinsModule)
    },
    {
        path: "ticket",
        loadChildren: () => import("./tickets/tickets.module").then(m => m.TicketsModule),
        canMatch: [AccessGuard],
        data: {
            ticketType: TicketType.Batch,
            access: {
                fallbackTo: "/dashboard/shipping-order",
                roles: [RolesEnum.Admin, RolesEnum.Batch, RolesEnum.Counter, RolesEnum.Manager, RolesEnum.Loader]
            }
        }
    },
    {
        path: "shipping-order",
        loadChildren: () => import("./tickets/tickets.module").then(m => m.TicketsModule),
        canMatch: [AccessGuard],
        data: {
            ticketType: TicketType.ShippingOrder,
            access: {
                fallbackTo: "/dashboard/to-deliver",
                roles: [RolesEnum.Admin, RolesEnum.Counter, RolesEnum.Manager, RolesEnum.Approver, RolesEnum.Preparator]
            }
        }
    },
    {
        path: "to-deliver",
        component: TicketListToDeliverComponent,
        data: {
            ticketType: TicketType.ShippingOrder
        }
    },
    {
        path: "spreader",
        loadChildren: () => import("./equipments/equipments.module").then(m => m.EquipmentsModule),
        canMatch: [AccessGuard],
        data: {
            access: {
                roles: [RolesEnum.Admin, RolesEnum.Manager, RolesEnum.Counter]
            }
        }
    },
    {
        path: "grain-box",
        loadChildren: () => import("./equipments/equipments.module").then(m => m.EquipmentsModule),
        canMatch: [AccessGuard],
        data: {
            access: {
                roles: [RolesEnum.Admin, RolesEnum.Manager, RolesEnum.Counter]
            }
        }
    },
    {
        path: "nurse",
        loadChildren: () => import("./equipments/equipments.module").then(m => m.EquipmentsModule),
        canMatch: [AccessGuard],
        data: {
            access: {
                roles: [RolesEnum.Admin, RolesEnum.Manager, RolesEnum.Counter]
            }
        }
    },
    {
        path: "vapam",
        loadChildren: () => import("./equipments/equipments.module").then(m => m.EquipmentsModule),
        canMatch: [AccessGuard],
        data: {
            access: {
                roles: [RolesEnum.Admin, RolesEnum.Manager, RolesEnum.Counter]
            }
        }
    },
    {
        path: "pump",
        loadChildren: () => import("./equipments/equipments.module").then(m => m.EquipmentsModule),
        canMatch: [AccessGuard],
        data: {
            access: {
                roles: [RolesEnum.Admin, RolesEnum.Manager, RolesEnum.Counter]
            }
        }
    },
    {
        path: "other",
        loadChildren: () => import("./equipments/equipments.module").then(m => m.EquipmentsModule),
        canMatch: [AccessGuard],
        data: {
            access: {
                roles: [RolesEnum.Admin, RolesEnum.Manager, RolesEnum.Counter]
            }
        }
    }
];
