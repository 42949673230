import { Injectable } from "@angular/core";
import { Query } from "@datorama/akita";
import { isDefined } from "@recursyve/nice-ts-utils";
import { filter, map, Observable } from "rxjs";
import { Accounts } from "../api/acl/account/models/accounts.model";
import { RolesEnum } from "../api/acl/account/models/enums/roles.enum";
import { InfoModel } from "../api/info/models/info.model";
import { AppState, AppStore } from "./app.store";

@Injectable()
export class AppQuery extends Query<AppState> {
    public readonly version$ = this.select("serverState").pipe(
        filter(isDefined),
        map(serverState => serverState.version)
    );

    constructor(protected store: AppStore) {
        super(store);
    }

    public selectServerState(): Observable<InfoModel | null> {
        return this.select(state => state.serverState);
    }

    public selectCurrentUser(): Observable<Accounts | null> {
        return this.select(s => s.currentUser);
    }

    public selectCurrentRole(): Observable<RolesEnum | null> {
        return this.select(s => s.currentRole);
    }
}
