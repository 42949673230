<div
    class="mt-8 mx-12 flex flex-col gap-7 overflow-hidden w-full h-fit"
    [niceLoadingOverlay]="loading$ | async"
    @fadeIn
>
    <span class="h1 my-5">{{ "ticket.list.shipping_order.title" | translate }}</span>

    <acl-ticket-list-item-list
        *ifRole="[RolesEnum.Counter, RolesEnum.Admin, RolesEnum.Shipping, RolesEnum.Manager]"
        class="w-full"
        [tickets]="tickets$ | async"
        [title]="'ticket.list.to_deliver' | translate"
        [ticketType]="TicketType.ShippingOrder"
        [emptyStateRef]="emptyStateDefault"
    ></acl-ticket-list-item-list>
</div>

<ng-template #emptyStateDefault>
    <div class="flex h-27 items-center justify-center">
        <span class="text-3xl font-bold">{{ "ticket.list.shipping_order.empty_state_title" | translate }}</span>

        <i class="fad fa-align-slash text-accent empty-icon text-9xl ml-27"></i>
    </div>
</ng-template>
