import { animate, query, stagger, style, transition, trigger } from "@angular/animations";
import { niceAnimations } from "@recursyve/nice-ui-kit.v2";

export const aclAnimations = [
    ...niceAnimations,
    trigger("fadeIn", [
        transition(":enter", [style({ opacity: 0 }), animate("1s 300ms ease-out", style({ opacity: 1 }))]),
        transition(":leave", [style({ opacity: 1 }), animate("300ms ease-in", style({ opacity: 0 }))])
    ]),
    trigger("items", [
        transition("* <=> *", [
            query(
                ":enter",
                [
                    style({
                        opacity: 0,
                        transform: "scale(0.5)"
                    }),
                    stagger("200ms", [animate("300ms ease-in")])
                ],
                { optional: true }
            )
        ])
    ]),
    trigger("errorSlideIn", [
        transition(":enter", [
            style({ transform: "translateY(-30%)" }),
            animate("100ms ease-in", style({ transform: "translateY(0%)" }))
        ])
    ])
];
