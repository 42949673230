import { Component, ElementRef, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { Router } from "@angular/router";
import { Subject, takeUntil } from "rxjs";
import { AppService } from "../../store/app.service";

@Component({
    selector: "acl-dashboard",
    templateUrl: "dashboard.template.html",
    styleUrls: ["dashboard.style.scss"],
    encapsulation: ViewEncapsulation.None
})
export class DashboardComponent implements OnInit, OnDestroy {
    private readonly unsubscribeAll$ = new Subject<void>();

    constructor(private router: Router, private elementRef: ElementRef, private appService: AppService) {}

    public ngOnInit(): void {
        this.router.events.pipe(takeUntil(this.unsubscribeAll$)).subscribe(_ => {
            this.elementRef.nativeElement.scrollIntoView();
        });

        this.appService.loadCurrentUser().pipe(takeUntil(this.unsubscribeAll$)).subscribe();
    }

    public ngOnDestroy(): void {
        this.unsubscribeAll$.next();
        this.unsubscribeAll$.complete();
    }
}
