import { NgIf } from "@angular/common";
import { Component, Input, ViewEncapsulation } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogRef } from "@angular/material/dialog";
import { MatIconModule } from "@angular/material/icon";

@Component({
    selector: "acl-base-modal",
    templateUrl: "./base-modal.template.html",
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [MatIconModule, MatButtonModule, NgIf],
    host: {
        class: "flex flex-col gap-6 h-full max-h-screen-90 min-w-120 max-w-screen-90"
    }
})
export class BaseModalComponent {
    @Input()
    public title: string;

    @Input()
    public subtitle: string;

    constructor(private matDialogRef: MatDialogRef<BaseModalComponent>) {}

    public clickCancel(): void {
        this.matDialogRef.close();
    }
}
