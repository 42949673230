import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { _BaseApi } from "../../../base.api";
import { FindOtherEquipmentNameOptionsDto } from "../dto/find-other-equipment-name-options.dto";
import { OtherEquipmentNames } from "../models/other-equipment-names.model";

@Injectable()
export class OtherEquipmentNamesApi extends _BaseApi {
    constructor(private http: HttpClient) {
        super("other-equipment-name", http);
    }

    public getAll(dto?: FindOtherEquipmentNameOptionsDto): Observable<OtherEquipmentNames[]> {
        return this.get("", { params: { ...dto }, type: OtherEquipmentNames });
    }
}
