import { NgModule } from "@angular/core";
import { IfNotRoleDirective } from "./if-not-role.directive";
import { IfRoleDirective } from "./if-role.directive";

@NgModule({
    declarations: [
        IfRoleDirective,
        IfNotRoleDirective
    ],
    exports: [
        IfRoleDirective,
        IfNotRoleDirective
    ]
})
export class DirectivesModule {}
