import { Injectable } from "@angular/core";
import { Query } from "@datorama/akita";
import {
    UpsertTicketAnticipatedPreparationDateModalState,
    UpsertTicketAnticipatedPreparationDateModalStore
} from "./upsert-ticket-anticipated-preparation-date-modal.store";

@Injectable()
export class UpsertTicketAnticipatedPreparationDateModalQuery extends Query<UpsertTicketAnticipatedPreparationDateModalState> {
    public readonly ticket$ = this.select("ticket");

    constructor(public readonly store: UpsertTicketAnticipatedPreparationDateModalStore) {
        super(store);
    }
}
