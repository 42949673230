import { inject, Injectable } from "@angular/core";
import { setLoadingAndError } from "@datorama/akita";
import { NiceToastService } from "@recursyve/nice-ui-kit.v2";
import { endOfDay, isAfter } from "date-fns";
import { EMPTY, Observable } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { FindTicketOptionsDto } from "../../../../../../api/acl/ticket/dto/find-ticket-options.dto";
import { Tickets } from "../../../../../../api/acl/ticket/models/tickets.model";
import { ApiService } from "../../../../../../api/api.service";
import { TicketType } from "../../../../../../api/common/enums/ticket-type.enum";
import { TicketListToDeliverQuery } from "./ticket-list-to-deliver.query";
import { TicketListToDeliverStore } from "./ticket-list-to-deliver.store";

@Injectable()
export class TicketListToDeliverService {
    private readonly store = inject(TicketListToDeliverStore);
    private readonly query = inject(TicketListToDeliverQuery);
    private readonly api = inject(ApiService);
    private readonly niceToastService = inject(NiceToastService);

    public getAllFromToday(dto: FindTicketOptionsDto): Observable<Tickets[]> {
        return this.api.ticket.getAllForToday(dto).pipe(
            setLoadingAndError(this.store),
            tap(tickets => this.store.set(tickets)),
            catchError(() => EMPTY)
        );
    }

    public todayTicketsUpdated(): Observable<Tickets[]> {
        return this.api.ticket.gateway.todayTicketsUpdated$;
    }

    public todayTicketsDeleted(): Observable<number[]> {
        return this.api.ticket.gateway.todayTicketsDeleted$;
    }

    public connectGateway(): void {
        this.api.ticket.gateway.connect();
    }

    public disconnectGateway(): void {
        this.api.ticket.gateway.disconnect();
    }

    public onTicketsUpdated(updatedTickets: Tickets[]): void {
        const tickets = this.query.getAll();
        const { ticketType } = this.store.getValue();
        if (!ticketType) {
            return;
        }
        const now = new Date();

        const toRemove = updatedTickets.filter(ticket => {
            if (!ticket.executionDate) {
                return true;
            }
            return isAfter(new Date(ticket.executionDate), endOfDay(now));
        });
        updatedTickets = updatedTickets.filter(ticket => !toRemove.includes(ticket));

        const untouchedTickets = tickets
            .filter(ticket => !updatedTickets.some(updatedTicket => ticket.id === updatedTicket.id))
            .filter(ticket => !toRemove.some(ticketToRemove => ticket.id === ticketToRemove.id));

        const allUpdatedTickets = [...untouchedTickets, ...updatedTickets];

        this.store.set(allUpdatedTickets.filter(ticket => ticket.type === ticketType));
    }

    public onTicketsDeleted(ids: number[]): void {
        this.store.remove(ids);
    }

    public completeTicket(id: number): Observable<Tickets> {
        return this.api.ticket.moveTicketToCompleted(id).pipe(
            setLoadingAndError(this.store),
            tap(ticket => {
                this.store.update(ticket.id, ticket);
                this.niceToastService.success("ticket.complete.success");
            }),
            catchError(() => {
                this.niceToastService.error("ticket.complete.error");
                return EMPTY;
            })
        );
    }

    public setTicketType(ticketType: TicketType): void {
        this.store.update({ ticketType });
    }
}
