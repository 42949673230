import { Pipe, PipeTransform } from "@angular/core";
import { TicketType } from "../api/common/enums/ticket-type.enum";

@Pipe({
    name: "ticketViewRouterLink",
    standalone: true
})
export class ticketViewRouterLinkPipe implements PipeTransform {
    public transform(ticketId: number, ticketType: TicketType): string {
        return `/dashboard/${ticketType === TicketType.ShippingOrder ? "shipping-order" : "ticket"}/${ticketId}`;
    }
}
