<nice-vertical-navigation class="print:hidden" [mode]="isMobile ? 'over' : 'side'" [name]="'navbar'">
    <ng-container niceVerticalNavigationHeader>
        <div class="h-full justify-center items-center pt-24 pb-8 px-6">
            <img class="logo-icon" src="../../../../../assets/images/logo-Agrocentre.svg" />
        </div>
    </ng-container>
    <ng-container niceVerticalNavigationFooter>
        <span class="flex text-primary/50 ml-4 mb-4">
            {{ "navigation.version" | translate }} {{ version$ | async }}
        </span>
    </ng-container>
</nice-vertical-navigation>
