import { Pipe, PipeTransform } from "@angular/core";
import { Tickets } from "../api/acl/ticket/models/tickets.model";

@Pipe({
    name: "ticketShippingOrderItemsHaveEquipment",
    standalone: true
})
export class TicketShippingOrderItemsHaveEquipmentPipe implements PipeTransform {
    public transform(ticket: Tickets | null | undefined): boolean {
        return ticket?.shippingOrder?.shippingOrderItems?.some(shippingOrderItem => shippingOrderItem.equipmentId);
    }
}
