import { BreakpointObserver, BreakpointState } from "@angular/cdk/layout";
import { Component, inject, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { NiceNavigationService } from "@recursyve/nice-ui-kit.v2";
import { Subject } from "rxjs";
import { delay, takeUntil } from "rxjs/operators";
import { AppQuery } from "../../../../store/app.query";

@Component({
    selector: "acl-navbar",
    templateUrl: "navbar.template.html",
    styleUrls: ["navbar.style.scss"],
    encapsulation: ViewEncapsulation.None
})
export class NavbarComponent implements OnInit, OnDestroy {
    public isMobile = false;

    private readonly appQuery = inject(AppQuery);

    protected readonly version$ = this.appQuery.version$;

    private readonly unsubscribeAll$ = new Subject<void>();

    constructor(private breakpointObserver: BreakpointObserver, private navigationService: NiceNavigationService) {}

    public ngOnInit(): void {
        this.breakpointObserver
            .observe(["(max-width: 1380px)"])
            .pipe(delay(100), takeUntil(this.unsubscribeAll$))
            .subscribe((result: BreakpointState) => {
                if (result.matches) {
                    this.isMobile = true;
                    this.navigationService.getComponent("navbar")?.close();
                } else {
                    this.isMobile = false;
                    this.navigationService.getComponent("navbar")?.open();
                }
            });
    }

    public ngOnDestroy(): void {
        this.unsubscribeAll$.next();
        this.unsubscribeAll$.complete();
    }
}
